

import { COMMON_GET_WEBSITES_DATA, COMMON_CLEAR_STORE_DATA } from "~/actions/types";

import { ProjectUrl } from "~/env";
export const GetWebsitesData = () => (dispatch) => {
    return fetch(`${ProjectUrl}/get-websites`, {
        method: "POST",
        credentials: "include",
        headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
    })
        .then((res) => (res.ok ? res.json() : Promise.reject(res)))
        .then((data) => {
            dispatch({ type: COMMON_GET_WEBSITES_DATA, data });
        })
        .catch((err) => {
            err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
        });
};

export const CommonClearStoreData = () => ({ type: COMMON_CLEAR_STORE_DATA });
