import React, { memo } from "react";

const Switch = ({ title, checked, onChange, primary, disableField, className }) => {
	return (
		<label className={`switch uk-inline ${className}`} title={title}>
			<input type="checkbox" title={title} checked={checked} onChange={onChange} disabled={disableField ? disableField() : null} />
			<span className={`slider ${!!primary ? "primary" : "success"}`}></span>
		</label>
	);
};

export default memo(Switch);
