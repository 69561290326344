// login action types
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_UPDATE_ERROR = "LOGIN_UPDATE_ERROR";
export const LOGIN_SET_LOADER = "LOGIN_SET_LOADER";
export const LOGIN_SET_EMAIL = "LOGIN_SET_EMAIL";
export const LOGIN_SET_USER_NAME = "LOGIN_SET_USER_NAME";
export const LOGIN_REMOVE_ERROR_ON_FOCUS = "LOGIN_REMOVE_ERROR_ON_FOCUS";

// common data page
export const COMMON_CLEAR_STORE_DATA = "COMMON_CLEAR_STORE_DATA";
export const COMMON_GET_WEBSITES_DATA = "COMMON_GET_WEBSITES_DATA";

// journey/user-groups types
export const USER_GROUP_SET_ERRORS = "USER_GROUP_SET_ERRORS";
export const USER_GROUP_SET_SEARCH = "USER_GROUP_SET_SEARCH";
export const USER_GROUP_SET_LOADER = "USER_GROUP_SET_LOADER";
export const USER_GROUP_REMOVE_ERROR = "USER_GROUP_REMOVE_ERROR";
export const USER_GROUP_SET_FILTER = "USER_GROUP_SET_FILTER";
export const USER_GROUP_CLEAR_USER_INFO = "USER_GROUP_CLEAR_USER_INFO";
export const USER_GROUP_GET_USER_GROUP = "USER_GROUP_GET_USER_GROUP";
export const USER_GROUP_APPEND_USER_GROUP = "USER_GROUP_APPEND_USER_GROUP";
export const USER_GROUP_CREATE_USER_GROUP = "USER_GROUP_CREATE_USER_GROUP";
export const USER_GROUP_SET_SAVE_BTN_DISABLED = "USER_GROUP_SET_SAVE_BTN_DISABLED";
export const USER_GROUP_SET_USER_GROUP_INFO = "USER_GROUP_SET_USER_GROUP_INFO";
export const USER_GROUP_EDIT_USER_GROUP = "USER_GROUP_EDIT_USER_GROUP";
export const USER_GROUP_UPDATE_USER_GROUP = "USER_GROUP_UPDATE_USER_GROUP";

// journey/user-journeys
export const USER_JOURNEY_SET_ERRORS = "USER_JOURNEY_SET_ERRORS";
export const USER_JOURNEY_SET_LOADER = "USER_JOURNEY_SET_LOADER";
export const USER_JOURNEY_SET_SEARCH = "USER_JOURNEY_SET_SEARCH";
export const USER_JOURNEY_REMOVE_ERRORS = "USER_JOURNEY_REMOVE_ERRORS";
export const USER_JOURNEY_SET_JOURNEY_INFO = "USER_JOURNEY_SET_JOURNEY_INFO";
export const USER_JOURNEY_SET_PAGE_LOADER = "USER_JOURNEY_SET_PAGE_LOADER";
export const USER_JOURNEY_GET_USER_JOURNEY = "USER_JOURNEY_GET_USER_JOURNEY";
export const USER_JOURNEY_SET_SAVE_BTN_DISABLE = "USER_JOURNEY_SET_SAVE_BTN_DISABLE";
export const USER_JOURNEY_CLEAR_JOURNEY_INFO = "USER_JOURNEY_CLEAR_JOURNEY_INFO";
export const USER_JOURNEY_CREATE_USER_JOURNEY = "USER_JOURNEY_CREATE_USER_JOURNEY";
export const USER_JOURNEY_APPEND_USER_JOURNEYS = "USER_JOURNEY_APPEND_USER_JOURNEYS";
export const USER_JOURNEY_EDIT_USER_JOURNEY = "USER_JOURNEY_EDIT_USER_JOURNEY";
export const USER_JOURNEY_UPDATE_USER_JOURNEY = "USER_JOURNEY_UPDATE_USER_JOURNEY";
export const USER_JOURNEY_SET_JOURNEY_WORKFLOW = "USER_JOURNEY_SET_JOURNEY_WORKFLOW";

//assets
export const ASSETS_SET_SEARCH = "ASSETS_SET_SEARCH";
export const ASSETS_APPEND_TO_ASSETS = "ASSETS_APPEND_TO_ASSETS";
export const ASSETS_GET_ASSETS = "ASSETS_GET_ASSETS";
export const ASSETS_SET_LOADER = "ASSETS_SET_LOADER";
export const ASSETS_SET_ASSETS_INFO = "ASSETS_SET_ASSETS_INFO";
export const ASSETS_CLEAR_ASSETS_INFO = "ASSETS_CLEAR_ASSETS_INFO";
export const ASSETS_SET_ERRORS = "ASSETS_SET_ERRORS";
export const ASSETS_REMOVE_ERRORS = "ASSETS_REMOVE_ERRORS";
export const ASSETS_CREATE_ASSET = "ASSETS_CREATE_ASSET";
export const ASSETS_UPDATE_ASSET = "ASSETS_UPDATE_ASSET";
export const ASSETS_SET_SAVE_BTN_DISABLED = "ASSETS_SET_SAVE_BTN_DISABLED";
export const ASSETS_EDIT_ASSET = "ASSETS_EDIT_ASSET"; //WHEN EDIT BUTTON IS CLICKED
export const ASSETS_SET_FILTER = "ASSETS_SET_FILTER";
export const ASSETS_SET_DATE_FILTER = "ASSETS_SET_DATE_FILTER";
export const ASSETS_SET_IS_FULL_URL = "ASSETS_SET_IS_FULL_URL";

export const ASSETS_SET_TYPE_FILTER = "ASSETS_SET_TYPE_FILTER";

// action types for components
export const COMPONENTS_SET_SEARCH = "COMPONENTS_SET_SEARCH";
export const COMPONENTS_SET_LOADER = "COMPONENTS_SET_LOADER";
export const COMPONENTS_APPEND_TO_COMPONENTS = "COMPONENTS_APPEND_TO_COMPONENTS";
export const COMPONENTS_GET_COMPONENTS = "COMPONENTS_GET_COMPONENTS";
export const COMPONENTS_SET_FILTER_BY = "COMPONENTS_SET_FILTER_BY";
export const COMPONENTS_SET_COMPONENT_INFO = "COMPONENTS_SET_COMPONENT_INFO";
export const COMPONENTS_SET_ASSET_DATA = "COMPONENTS_SET_ASSET_DATA";
export const COMPONENTS_CLEAR_COMPONENT_INFO = "COMPONENTS_CLEAR_COMPONENT_INFO";
export const COMPONENTS_SET_ERRORS = "COMPONENTS_SET_ERRORS";
export const COMPONENTS_SET_SAVE_BTN_DISABLE = "COMPONENTS_SET_SAVE_BTN_DISABLE";
export const COMPONENTS_CREATE_COMPONENT = "COMPONENTS_CREATE_COMPONENT";
export const COMPONENTS_UPDATE_COMPONENT = "COMPONENTS_UPDATE_COMPONENT";
export const COMPONENTS_EDIT_COMPONENT = "COMPONENTS_EDIT_COMPONENT";
export const COMPONENTS_SET_PARAM_DATA_INFO = "COMPONENTs_SET_PARAM_DATA_INFO";
export const COMPONENTS_SET_HEADER_PARAM_INFO = "COMPONENTS_SET_HEADER_PARAM_INFO";
export const COMPONENTS_SET_TESTIMONIAL_DATA = "COMPONENTS_SET_TESTIMONIAL_DATA";
export const COMPONENTS_EDIT_TESTIMONIAL_DATA = "COMPONENTS_EDIT_TESTIMONIAL_DATA";
export const COMPONENTS_CLEAR_TESTIMONIAL_DATA = "COMPONENTS_CLEAR_TESTIMONIAL_DATA";
export const COMPONENTS_SAVE_TESTIMONIAL = "COMPONENTS_SAVE_TESTIMONIAL";
export const COMPONENTS_DELETE_TESTIMONIAL = "COMPONENTS_DELETE_TESTIMONIAL";
export const COMPONENTS_SET_VIDEOS_FIELD = "COMPONENTS_SET_VIDEOS_FIELD";
export const COMPONENTS_ADD_DELETE_VIDEOS = "COMPONENTS_ADD_DELETE_VIDEOS";

export const COMPONENTS_SET_FAQ_DATA = "COMPONENTS_SET_FAQ_DATA";
export const COMPONENTS_CLEAR_FAQ_DATA = "COMPONENTS_CLEAR_FAQ_DATA";
export const COMPONENTS_SAVE_FAQ = "COMPONENTS_SAVE_FAQ";
export const COMPONENTS_DELETE_FAQ = "COMPONENTS_DELETE_FAQ";
export const COMPONENTS_EDIT_FAQ_DATA = "COMPONENTS_EDIT_FAQ_DATA";
export const COMPONENTS_CLEAR_VIDEO = "COMPONENTS_CLEAR_VIDEO";
export const COMPONENTS_SET_TAGS_MODAL = "COMPONENTS_SET_TAGS_MODAL";
export const COMPONENTS_RESET_TAGS = "COMPONENTS_RESET_TAGS";

// wizard component
export const COMPONENT_SET_WIZARD_PARAMS_DATA = "COMPONENT_SET_WIZARD_PARAMS_DATA";
export const COMPONENT_SET_WIZARD_PARAMS_FALSE = "COMPONENT_SET_WIZARD_PARAMS_FALSE";
export const COMPONENT_SET_WIZARD_HEADER_PARAMS_DATA = "COMPONENT_SET_WIZARD_HEADER_PARAMS_DATA";
export const COMPONENT_SET_WIZARD_HEADER_PARAMS_FLOAT_INFO = "COMPONENT_SET_WIZARD_HEADER_PARAMS_FLOAT_INFO";

// offers

export const OFFERS_SET_INITIAL_DATA = "OFFERS_SET_INITIAL_DATA";
export const OFFERS_SET_SEARCH = "OFFERS_SET_SEARCH";
export const OFFERS_SET_ASSETS = "OFFERS_SET_ASSETS";
export const OFFERS_SET_LOADER = "OFFERS_SET_LOADER";
export const OFFERS_SET_FILTER = "OFFERS_SET_FILTER";
export const OFFERS_APPEND_TO_OFFERS = "OFFERS_APPEND_TO_OFFERS";
export const OFFERS_GET_OFFERS = "OFFERS_GET_OFFERS";
export const OFFERS_SET_BRANDS = "OFFERS_SET_BRANDS";
export const OFFERS_SET_CATEGORIES = "OFFERS_SET_CATEGORIES";
export const OFFERS_CHANGE_OFFER_INFO = "OFFERS_CHANGE_OFFER_INFO";
export const OFFERS_SET_ERRORS = "OFFERS_SET_ERRORS";
export const OFFERS_SET_SAVE_BTN_DISABLE = "OFFERS_SET_SAVE_BTN_DISABLE";
export const OFFERS_UPDATE_OFFER = "OFFERS_UPDATE_OFFER";
export const OFFERS_CREATE_OFFER = "OFFERS_CREATE_OFFER";
export const OFFERS_CLEAR_OFFER_INFO = "OFFERS_CLEAR_OFFER_INFO";
export const OFFERS_EDIT_OFFER = "OFFERS_EDIT_OFFER";
export const OFFERS_SET_ASSETS_IMG_INFO = "OFFERS_SET_ASSETS_IMG_INFO";
export const OFFERS_SET_TEMP_CATEGORY_BRAND = "OFFERS_SET_TEMP_CATEGORY_BRAND";

// PAGES

export const PAGES_SET_LOADER = "PAGES_SET_LOADER";
export const PAGES_SET_FILTER = "PAGES_SET_FILTER";
export const PAGES_SET_SAVE_BTN_DISABLE = "PAGES_SET_SAVE_BTN_DISABLE";
export const PAGES_SET_SEARCH = "PAGES_SET_SEARCH";
export const PAGES_SET_PAGE_INFO = "PAGES_SET_PAGE_INFO";
export const PAGES_CLEAR_PAGE_INFO = "PAGES_CLEAR_PAGE_INFO";
export const PAGES_EDIT_PAGE = "PAGES_EDIT_PAGE";
export const PAGES_APPEND_TO_PAGES = "PAGES_APPEND_TO_PAGES";
export const PAGES_GET_PAGES = "PAGES_GET_PAGES";
export const PAGES_SET_ERRORS = "PAGES_SET_ERRORS";
export const PAGES_UPDATE_PAGE = "PAGES_UPDATE_PAGE";
export const PAGES_CREATE_PAGE = "PAGES_CREATE_PAGE";

// Competitors Variants Page
export const COMPETITORS_VARIANTS_GET_DATA = "COMPETITORS_VARIANTS_GET_DATA";
export const COMPETITORS_VARIANTS_SET_FILTER = "COMPETITORS_VARIANTS_SET_FILTER";
export const COMPETITORS_VARIANTS_SET_LOADER = "COMPETITORS_VARIANTS_SET_LOADER";
export const COMPETITORS_VARIANTS_APPEND_DATA = "COMPETITORS_VARIANTS_APPEND_DATA";
export const COMPETITORS_VARIANTS_ON_CHANGE_URL = "COMPETITORS_VARIANTS_ON_CHANGE_URL";
export const COMPETITORS_VARIANTS_ADD_DELETE_URL = "COMPETITORS_VARIANTS_ADD_DELETE_URL";
export const COMPETITORS_VARIANTS_SET_ERROR_LIST = "COMPETITORS_VARIANTS_SET_ERROR_LIST";
export const COMPETITORS_VARIANTS_SET_EXPORT_MODAL = "COMPETITORS_VARIANTS_SET_EXPORT_MODAL";
export const COMPETITORS_VARIANTS_GET_INITIAL_DATA = "COMPETITORS_VARIANTS_GET_INITIAL_DATA";
export const COMPETITORS_VARIANT_OPEN_ADD_EDIT_MODAL = "COMPETITORS_VARIANT_OPEN_ADD_EDIT_MODAL";
export const COMPETITORS_VARIANTS_ADD_UPDATE_VARIANT = "COMPETITORS_VARIANTS_ADD_UPDATE_VARIANT";
export const COMPETITORS_VARIANTS_SET_SELECTED_OPTION = "COMPETITORS_VARIANTS_SET_SELECTED_OPTION";
export const COMPETITORS_VARIANTS_GET_VARIANT_OPTIONS = "COMPETITORS_VARIANTS_GET_VARIANT_OPTIONS";
export const COMPETITORS_VARIANT_CLEAR_ADD_EDIT_MODAL = "COMPETITORS_VARIANT_CLEAR_ADD_EDIT_MODAL";
export const COMPETITORS_VARIANTS_ON_CHANGE_EXPORT_MODAL = "COMPETITORS_VARIANTS_ON_CHANGE_EXPORT_MODAL";
export const COMPETITORS_VARIANTS_CLEAR_BULK_UPLOAD_MODAL = "COMPETITORS_VARIANTS_CLEAR_BULK_UPLOAD_MODAL";
export const COMPETITORS_VARIANT_ON_CHANGE_ADD_EDIT_MODAL = "COMPETITORS_VARIANT_ON_CHANGE_ADD_EDIT_MODAL";
export const COMPETITORS_VARIANTS_ON_CHANGE_BULK_UPLOAD_MODAL = "COMPETITORS_VARIANTS_ON_CHANGE_BULK_UPLOAD_MODAL";
export const COMPETITORS_VARIANTS_SET_CHECKED_BY = "COMPETITORS_VARIANTS_SET_CHECKED_BY";

// Discount Info
export const SELLER_DEALS_GET_DATA = "SELLER_DEALS_GET_DATA";
export const SELLER_DEALS_SET_FILTER = "SELLER_DEALS_SET_FILTER";
export const SELLER_DEALS_GET_OPTIONS = "SELLER_DEALS_GET_OPTIONS";
export const SELLER_DEALS_APPEND_DATA = "SELLER_DEALS_APPEND_DATA";
export const SELLER_DEALS_SET_ERROR_LIST = "SELLER_DEALS_SET_ERROR_LIST";
export const SELLER_DEALS_ADD_UPDATE_DEAL = "SELLER_DEALS_ADD_UPDATE_DEAL";
export const SELLER_DEALS_GET_INITIAL_DATA = "SELLER_DEALS_GET_INITIAL_DATA";
export const SELLER_DEALS_RESET_INITIAL_DATA = "SELLER_DEALS_RESET_INITIAL_DATA";
export const SELLER_DEALS_SET_SELECTED_OPTION = "SELLER_DEALS_SET_SELECTED_OPTION";
export const SELLER_DEALS_SET_CREATE_EDIT_MODAL = "SELLER_DEALS_SET_CREATE_EDIT_MODAL";
export const SELLER_DEALS_CLEAR_CREATE_EDIT_MODAL = "SELLER_DEALS_CLEAR_CREATE_EDIT_MODAL";
export const SELLER_DEALS_CLEAR_BULK_UPLOAD_MODAL = "SELLER_DEALS_CLEAR_BULK_UPLOAD_MODAL";
export const SELLER_DEALS_ON_CHANGE_BULK_UPLOAD_MODAL = "SELLER_DEALS_ON_CHANGE_BULK_UPLOAD_MODAL";
export const SELLER_DEALS_ON_CHANGE_CREATE_EDIT_MODAL = "SELLER_DEALS_ON_CHANGE_CREATE_EDIT_MODAL";

// Customer retention dashboard 
export const CUSTOMER_RETENTION_DASHBOARD_SET_DATE = "CUSTOMER_RETENTION_DASHBOARD_SET_DATE";
export const CUSTOMER_RETENTION_DASHBOARD_GET_DATA = "CUSTOMER_RETENTION_DASHBOARD_GET_DATA";
export const CUSTOMER_RETENTION_DASHBOARD_SET_FILTER = "CUSTOMER_RETENTION_DASHBOARD_SET_FILTER";

// MENU Page 
export const MENU_GET_DATA = "MENU_GET_DATA";
export const MENU_OPEN_MODAL = "MENU_OPEN_MODAL";
export const MENU_SET_FILTER = "MENU_SET_FILTER";
export const MENU_APPEND_DATA = "MENU_APPEND_DATA";
export const MENU_UPDATE_MENU = "MENU_UPDATE_MENU";
export const MENU_SET_ERROR_LIST = "MENU_SET_ERROR_LIST";
export const MENU_CLEAR_MODAL_DATA = "MENU_CLEAR_MODAL_DATA";
export const MENU_ON_CHANGE_MODAL_DATA = "MENU_ON_CHANGE_MODAL_DATA";
