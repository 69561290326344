import { LOGIN_SET_EMAIL, LOGIN_UPDATE_ERROR, LOGIN_REMOVE_ERROR_ON_FOCUS, LOGIN_SET_LOADER, LOGIN_SET_USER_NAME } from "~/actions/types.js";

const INITIAL_STATE = {
	user_name: "",
	email: "",
	loader: false,
	errors: {}
};

export const LoginReducer = (state = INITIAL_STATE, action) => {
	const newState = Object.assign({}, state);

	if (action.type === LOGIN_SET_EMAIL) {
		newState.email = action.value;
		return newState;
	}

	if (action.type === LOGIN_UPDATE_ERROR) {
		newState.errors = action.errors;
		return newState;
	}

	if (action.type === LOGIN_REMOVE_ERROR_ON_FOCUS) {
		if (!!newState.errors[action.key]) {
			const error = { ...newState.errors };
			delete error[action.key];
			newState.errors = error;
		}
		return newState;
	}

	if (action.type === LOGIN_SET_LOADER) {
		newState.loader = action.loader;
		return newState;
	}

	if (action.type === LOGIN_SET_USER_NAME) {
		newState.user_name = action.user_name;
		return newState;
	}

	return state;
};
