const Loader = {
	start: () => {
		nanobar.go(50);
	},
	stop: () => {
		nanobar.go(100);
	},
};

export default Loader;
