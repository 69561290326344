const Alertify = {
	success: (alert_text) => {
		alertify.success(alert_text);
	},
	error: (alert_text) => {
		alertify.error(alert_text);
	}
};

export default Alertify;
