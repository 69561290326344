export const Textarea = (props) => {
	const default_props = {
		id: props.id,
		name: props.name,
		value: props.value,
		maxLength: props.maxLength,
		minLength: props.minLength,
		disabled: props.disabled,
		placeholder: props.placeholder,
		autoFocus: props.autoFocus,
		required: props.required,
		style: props.style,
		onFocus: props.onFocus,
		onChange: props.onChange,
		onKeyPress: props.onKeyPress,
	};
	const { className, error, success, small, large, label, stacked } = { ...props };

	let extraClass = "uk-textarea";
	if (small) {
		extraClass += " uk-form-small";
	}
	if (large) {
		extraClass += " uk-form-large";
	}

	if (className) {
		extraClass += ` ${className}`;
	}

	return (
		<div className={`formControl ${error ? "error" : success ? "success" : null} ${stacked ? "stacked" : null}`}>
			{label ? <label>{label}</label> : null}

			<textarea {...default_props} className={extraClass} />

			{(typeof error != "boolean" && error) || (typeof success != "boolean" && success) ? <div className="message">{error || success}</div> : null}
		</div>
	);
};
