import { useEffect } from "react";

import { ProjectUrl } from "~/env";
import Alertify from "~/scripts/alertify.js";

import { GetWebsitesData } from "~/actions/common-action.js";

import { LOGIN_SET_EMAIL, LOGIN_UPDATE_ERROR, LOGIN_REMOVE_ERROR_ON_FOCUS, LOGIN_SET_LOADER, LOGIN_SET_USER_NAME } from "./types.js";

const email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

/** @desc function to set email in reducer. @param {string} value */
export const LoginSetEmail = (value) => ({ type: LOGIN_SET_EMAIL, value });

/** @desc function to delete error onFocused. @param {String} key  */
export const LoginOnFocus = (key) => ({ type: LOGIN_REMOVE_ERROR_ON_FOCUS, key });

/** @desc to validate email and password value. */
const _LoginValidation = (email, pwd) => {
	const errors = {};

	if (!email || email === "") errors.email = "Email is required";
	else if (!email_validator.test(email)) errors.email = "Invalid Email";

	if (!pwd || pwd.trim() === "") errors.pwd = "Password is required";

	return errors;
};

/** @desc fetch function for login. */
export const LoginSubmitUser = (pwd, token) => (dispatch, getState) => {
	const { email } = getState();

	const validation_errors = _LoginValidation(email, pwd);
	if (Object.keys(validation_errors).length > 0) {
		dispatch({ type: LOGIN_UPDATE_ERROR, errors: validation_errors });
		return Promise.reject();
	}

	dispatch({ type: LOGIN_SET_LOADER, loader: true });

	return fetch(`${ProjectUrl}/login/userlogin`, {
		method: "POST",
		credentials: "include",
		headers: {
			"content-type": "application/json",
			"X-Requested-With": "XMLHttpRequest",
			"recaptcha-token": token

		},
		body: JSON.stringify({ email, pwd })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (!data.status) {
				Alertify.error("Invalid Email or Password");
			}
			dispatch({ type: LOGIN_SET_LOADER, loader: false });
			return Promise.resolve(data.user_name);
		})
		.catch((err) => {
			dispatch({ type: LOGIN_SET_LOADER, loader: false });
			if (err instanceof Error) {
				Alertify.error("An Error has occurred");
			} else {
				const err_clone = err.clone(); // for reading response second time
				const promises = [err.json(), err_clone.text()];
				Promise.any(promises).then((data) => (data.error ? alertify.error(data.error) : alertify.error(data)));
			}
			Promise.reject();
		});
};

/** @desc fetch func to change password.*/
export const LoginChangePassword = (old_password, new_password, confirm_password) => {
	return fetch(`${ProjectUrl}/login/setnewpassword`, {
		method: "post",
		credentials: "include",
		headers: { "Content-Type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ old_password, new_password, confirm_password })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			Alertify.success("Password Successfully Changed");
		})
		.catch((error) => {
			if (error instanceof Error) {
				Alertify.error("An Error has occurred");
			} else {
				error.text().then((data) => {
					Alertify.error(data);
				});
			}
		});
};

//#region for main left sidebar
export const useUserName = () => (dispatch) => {
	useEffect(() => {
		let user_name = localStorage.getItem("user_name");
		if (user_name === null || user_name === "") {
			user_name = "User";
		}
		dispatch({ type: LOGIN_SET_USER_NAME, user_name });
		dispatch(GetWebsitesData());
	}, []);
};

//#endregion
